<template>
  <v-card class="pt-16 pb-7 text-center">
    <v-card-title class="justify-center">{{ title }}</v-card-title>
    <!-- Form -->
    <v-row>
      <v-col cols="12">
        <t-form-wrapper
          v-if="formData"
          :form-data="formData"
          :initial-data="initialData"
          custom-cancel-action
          :edit-info="editInfo"
          :callback-route="callbackRoute"
          :store-url="countries ? countriesStoreUrl : storeUrl"
          :show-stepper="false"
          string-file
          @cancel-form="cancel"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "TFormDialog",
  components: {
    TFormWrapper: () => import("@/components/Form/TFormWrapper"),
  },
  props: {
    initialData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    formData: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      required: true,
    },
    editInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
    countries: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    storeUrl() {
      return this.editInfo
        ? "adminPartners/updateItem"
        : "adminPartners/insertItem";
    },
    countriesStoreUrl() {
      return this.editInfo
        ? "adminCountries/updateItem"
        : "adminCountries/insertItem";
    },
    callbackRoute() {
      return this.countries ? "countries" : "list-partner";
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--block {
  min-width: auto !important;
}
.cta {
  max-width: 140px;
}
</style>
